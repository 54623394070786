import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import './Pages.scss';

import routeKeys from '../../routes/keys';
import { getTransitionDuration } from '../../data/pages-transitions';

const Landing = lazy(() => import(/* webpackChunkName: "Landing" */ '../../pages/Landing/Landing'));
// const Menu = lazy(() => import(/* webpackChunkName: "Menu" */ '../../pages/Menu/Menu'));
const Page = lazy(() => import(/* webpackChunkName: "Page" */ '../../pages/Page/Page'));
const Contacts = lazy(() => import(/* webpackChunkName: "Contacts" */ '../../pages/Contacts/Contacts'));
const NotFound = lazy(() => import('../../pages/NotFound/NotFound'));

// const getFields = (collection, id) => {
//   if (collection) {
//     const results = collection.filter(page => page.name === id)[0];
//     return results;
//   }
// };

const Pages = ({ location, ...props }) => {
  const {
    landing,
    contatti,
    ristorante,
    drinks,
    pasticceria,
    // 'menu-ristorante': menuRistorante,
    // 'menu-bar': menuBar,
  } = props.siteData;
  return (
    <main className={classnames('Pages', props.className)} role="main">
      <TransitionGroup component={Fragment}>
        <Transition appear key={location.pathname} timeout={getTransitionDuration(location.pathname)}>
          {(state) => (
            <Suspense fallback={<div className="loading" />}>
              <Switch location={location}>
                <Route
                  exact
                  path={routeKeys.Landing}
                  render={() => <Landing pageData={landing} transitionState={state} />}
                />
                {/* <Route
                  exact
                  path={routeKeys.MenuBar}
                  render={() => <Menu pageData={menuBar} className="Menu-bar" transitionState={state} />}
                />
                <Route
                  exact
                  path={routeKeys.MenuRistorante}
                  render={() => <Menu pageData={menuRistorante} className="Menu-ristorante" transitionState={state} />}
                /> */}
                <Route
                  exact
                  path={routeKeys.Ristorante}
                  render={() => <Page pageData={ristorante} className="Ristorante" transitionState={state} />}
                />
                <Route
                  exact
                  path={routeKeys.Drinks}
                  render={() => <Page pageData={drinks} className="Bar" transitionState={state} />}
                />
                <Route
                  exact
                  path={routeKeys.Pasticceria}
                  render={() => <Page pageData={pasticceria} className="Pasticceria" transitionState={state} />}
                />
                <Route
                  exact
                  path={routeKeys.Contatti}
                  render={() => <Contacts pageData={contatti} className="Contatti" transitionState={state} />}
                />
                <Route render={() => <NotFound />} />
              </Switch>
            </Suspense>
          )}
        </Transition>
      </TransitionGroup>
    </main>
  );
};

Pages.propTypes = checkProps({
  className: PropTypes.string,
  siteData: PropTypes.object.isRequired,
});

Pages.defaultProps = {};

export default withRouter(Pages);
