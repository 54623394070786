import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { BaseLink } from '@jam3/react-ui';
import cleanPath from 'remove-trailing-separator';
import PrefetchLink from '../PrefetchLink/PrefetchLink';
import { ReactComponent as LogoBg } from '../../assets/svg/landing_bg.svg';
import sanitizer from '../../util/sanitizer';
import routeKeys from '../../routes/keys';
import navData from '../../data/nav-menu';
import { ReactComponent as FacebookIcon } from '../../assets/svg/facebook.svg';
import { ReactComponent as TripIcon } from '../../assets/svg/tripadvisor.svg';
import { ReactComponent as InstaIcon } from '../../assets/svg/instagram.svg';

import './Nav.scss';

const Nav = (props) => {
  return (
    <div className="header__nav-content">
      <div className="header__nav-content__bg">
        <LogoBg></LogoBg>
      </div>
      <nav className="main-nav">
        <ul className="main-nav__menu">
          {navData.links.map((link, index) => (
            <li key={index} className="main-nav__menu-li">
              <PrefetchLink
                link={link.path}
                className={classnames({ active: cleanPath(props.location.pathname) === cleanPath(link.path) })}
              >
                {link.text}
              </PrefetchLink>
            </li>
          ))}
        </ul>
      </nav>
      <section className="info-nav">
        <a className="info-nav__item" href="/assets/docs/menu-ristorante.pdf" target="_blank" rel="noopener noreferrer">
          <span>{sanitizer('Menu')}</span>
        </a>

        {/* <BaseLink
          className={classnames('info-nav__item', {
            'info-nav__item--active': cleanPath(props.location.pathname) === cleanPath(routeKeys.MenuBar),
          })}
          link={routeKeys.MenuBar}
        >
          <span>{sanitizer('Menu Bar')}</span>
        </BaseLink> */}
        {/* <BaseLink
          className={classnames('info-nav__item', {
            'info-nav__item--active': cleanPath(props.location.pathname) === cleanPath(routeKeys.MenuRistorante),
          })}
          link={routeKeys.MenuRistorante}
        >
          <span>{sanitizer('Menu Ristorante')}</span>
        </BaseLink> */}
        <BaseLink
          className={classnames('info-nav__item', {
            'info-nav__item--active': cleanPath(props.location.pathname) === cleanPath(routeKeys.Contatti),
          })}
          link={routeKeys.Contatti}
        >
          <span>{sanitizer('Contatti')}</span>
        </BaseLink>
      </section>
      <div className="social-nav">
        <BaseLink className="social-nav__insta" link="https://www.instagram.com/centrale_montecatini/">
          <InstaIcon></InstaIcon>
        </BaseLink>
        <BaseLink className="social-nav__fb" link="https://www.facebook.com/CentraleMontecatini">
          <FacebookIcon></FacebookIcon>
        </BaseLink>
        <BaseLink
          className="social-nav__trip"
          link="https://www.tripadvisor.it/Restaurant_Review-g194830-d2294503-Reviews-Centrale_Il_Ristorante_La_Pizzeria-Montecatini_Terme_Province_of_Pistoia_Tuscany.html"
        >
          <TripIcon></TripIcon>
        </BaseLink>
      </div>
    </div>
  );
};

export default withRouter(Nav);
